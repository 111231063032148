/* eslint-disable */
/* tslint:disable */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'src/decorator/inject-intl';
import Base from 'src/core/base';
import Image from 'src/components/image';
import Toast from 'src/components/toast';
import { getRegionList } from 'src/api/region';
import { remoteRegion } from 'src/api';
import { BusinessEvent } from 'src/common/constant';
import Logger from 'src/utils/logger';

import './index.scss';

const logger = new Logger('ShipInfo');

interface Prop {
    config: any;
    commodity: any;
    selectedPackage: any; //选中的套餐
    onShipInfoChange: (spuAttr: any) => void;
    onSubmitOrder: (shipInfo: any) => void;
    onRemoteRegion: (region: any) => void;
}
interface State {
    selectedDistribution: any;
    realName: string;
    phone: string;
    email: string;
    shipZipCode: string;
    shipMethod: string;
    detailAddress: string;
    selectedRegion: any[];
    remark: string;
    region: any[];
    remoteLogisticsFee: number;
}

@injectIntl()
export default class ShipInfo extends Base<Prop, State> {
    private distributionModeStyle: any = {
        TWN_HEIMAO_XINZHU: 'akmall-payment-payOnDelivery',
        TWN_SEVEN_ELEVEN: 'akmall-payment-711',
        TWN_FAMIPORT: 'akmall-payment-quanjia',
    };
    private zipCodeRegExp: RegExp = /^\d+-?\d*$/;
    private phoneRegExp: RegExp = /^09[0-9]{8}$/;
    private mailRegExp: RegExp =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    constructor(props: any) {
        super(props);
        this.state = {
            selectedDistribution: {},
            realName: '',
            phone: '',
            email: '',
            shipZipCode: '',
            shipMethod: '',
            detailAddress: '',
            selectedRegion: [],
            remark: '',
            region: [],
            remoteLogisticsFee: 0,
        };
    }

    private getRegionList = async (distributionMode: any = {}, parentLevel: number = 0, parentId: string | null = null) => {
        const { config } = this.props;
        const { promoteId } = this.props.commodity;
        const regionParams = {
            parentId,
            promoteId: promoteId,
            parentLevel: parentLevel,
            regionCode: config?.regionCode,
            distributionMode: distributionMode.value,
        };
        this.setState({ selectedDistribution: distributionMode });
        let response = await getRegionList(regionParams);
        return response;
    };

    changeHandler = ({ realName, phone, email, shipZipCode, shipMethod, detailAddress, remark }: any) => {
        this.setState(
            {
                realName: realName ?? this.state.realName,
                phone: phone ?? this.state.phone,
                email: email ?? this.state.email,
                shipZipCode: shipZipCode ?? this.state.shipZipCode,
                shipMethod: shipMethod ?? this.state.shipMethod,
                detailAddress: detailAddress ?? this.state.detailAddress,
                remark: remark ?? this.state.remark,
            },
            () => {
                // this.props?.onShipInfoChange(JSON.parse(JSON.stringify(this.state)));
                const shipInfo = JSON.parse(JSON.stringify(this.state));
                this.props.onShipInfoChange(shipInfo);
            },
        );
    };

    changeDistributionHandler = async (dis: any) => {
        this.setState({ region: [] });
        this.setState({ remoteLogisticsFee: 0 });
        this.props?.onRemoteRegion({ remoteLogisticsFee: 0, regionId: '' });
        const { data } = await this.getRegionList(dis);
        this.setState({ region: data, selectedDistribution: dis, selectedRegion: [] }, () => {
            if (data?.[0]?.level >= 0) {
                this.regionChangeHandler(data?.[0]?.value, data?.[0]?.level);
            }
            // this.props?.onShipInfoChange(JSON.parse(JSON.stringify(this.state)));
            const shipInfo = JSON.parse(JSON.stringify(this.state));
            this.props.onShipInfoChange(shipInfo);
        });
    };

    regionChangeHandler = async (value: string, level: number = -1) => {
        if (level === 1) {
            let findNode = false;
            for (let i = 0, iLen = this.state.region.length; i < iLen; i++) {
                const region = this.state.region[i];
                if (region.value === value) {
                    const { data } = await this.getRegionList(this.state.selectedDistribution, region.level, region.id);
                    region.children = data;
                    this.setState({ selectedRegion: [region] }, () => {
                        const shipInfo = JSON.parse(JSON.stringify(this.state));
                        this.props.onShipInfoChange(shipInfo);
                    });
                    findNode = true;
                    if (data?.length > 0) {
                        this.regionChangeHandler(data[0].value, level + 1);
                    } else {
                        this.getRemoteRegion(region.id);
                    }
                    break;
                }
            }
            if (!findNode) {
                this.setState({ selectedRegion: [] }, () => {
                    const shipInfo = JSON.parse(JSON.stringify(this.state));
                    this.props.onShipInfoChange(shipInfo);
                });
            }
        }
        if (level >= 2) {
            const { selectedRegion } = this.state;
            let findNode = false;
            for (let i = 0, iLen = selectedRegion[level - 2].children?.length; i < iLen; i++) {
                const region = selectedRegion[level - 2].children[i];
                if (region.value === value) {
                    const { data } = await this.getRegionList(this.state.selectedDistribution, region.level, region.id);
                    region.children = data;
                    selectedRegion.length = level - 1;
                    selectedRegion.push(region);
                    this.setState({ selectedRegion }, () => {
                        const shipInfo = JSON.parse(JSON.stringify(this.state));
                        this.props.onShipInfoChange(shipInfo);
                    });
                    findNode = true;
                    if (data?.length > 0) {
                        this.regionChangeHandler(data[0].value, level + 1);
                    } else {
                        this.getRemoteRegion(region.id);
                    }
                    break;
                }
            }
            if (!findNode) {
                const newSelectedRegion = selectedRegion.slice(0, level);
                newSelectedRegion[level - 1].children = [];
                this.setState({ selectedRegion: newSelectedRegion }, () => {
                    const shipInfo = JSON.parse(JSON.stringify(this.state));
                    this.props.onShipInfoChange(shipInfo);
                });
            }
        }
    };

    async getRemoteRegion(regionId: string) {
        if (!regionId) return;
        const promoteId = sessionStorage.getItem('promoteId');
        const { data } = await remoteRegion({ regionId, promoteId });
        this.setState({ remoteLogisticsFee: data.remoteLogisticsFee });
        this.props?.onRemoteRegion({ remoteLogisticsFee: data.remoteLogisticsFee, regionId });
    }

    submitValidate = () => {
        const { realName, phone, email, shipMethod, shipZipCode, detailAddress, selectedRegion, selectedDistribution } =
            this.state;
        const { config, intl, commodity } = this.props;
        if (!realName?.trim()) {
            Toast.error(intl?.formatMessage({ id: 'input.name' })!);
            return false;
        }
        if (!phone?.trim()) {
            Toast.error(intl?.formatMessage({ id: 'input.phone' })!);
            return false;
        }

        if (commodity.regionCode == 'TWN' && phone && !this.phoneRegExp.test(phone)) {
            Toast.error(intl?.formatMessage({ id: 'input.phone' }) + '(格式：09XXXXXXXX)');
            return false;
        }
        if (selectedRegion?.length > 0) {
            //下拉框有多个，并且最后一个下拉框没有选中
            if (selectedRegion[selectedRegion.length - 1]?.children?.length > 0) {
                Toast.error(intl?.formatMessage({ id: 'ship-info.select-region' })!);
                return false;
            }
            let noSelected = false;
            for (let i = 0, iLen = selectedRegion?.length; i < iLen; i++) {
                const reg = selectedRegion[i];
                if (!reg.value?.trim()) {
                    noSelected = true;
                    break;
                }
            }
            if (noSelected) {
                Toast.error(intl?.formatMessage({ id: 'ship-info.select-region' })!);
                return false;
            }
        }

        if (!selectedDistribution.hideAddressInput && !detailAddress?.trim()) {
            Toast.error(intl?.formatMessage({ id: 'input.detail-address' })!);
            return false;
        }

        if (config?.requiredConfig?.shipEmail?.required && !email) {
            Toast.error(intl!.formatMessage({ id: 'input.email' }));
            return false;
        }

        //input-wrong-email
        if (config?.requiredConfig?.shipEmail?.required && email && !this.mailRegExp.test(email)) {
            Toast.error(intl!.formatMessage({ id: 'input-wrong-email' }));
            return false;
        }

        if (config?.requiredConfig?.shipZipCode?.required && !shipZipCode) {
            Toast.error(intl!.formatMessage({ id: 'input.zip-code' }));
            return false;
        }

        //input-wrong-zipcode
        if (config?.requiredConfig?.shipZipCode?.required && shipZipCode && !this.zipCodeRegExp.test(shipZipCode)) {
            Toast.error(intl!.formatMessage({ id: 'input-wrong-zipcode' }));
            return false;
        }

        return true;
    };

    submitOrderHandler = async () => {
        const result = this.submitValidate();
        if (result) {
            const shipInfo = JSON.parse(JSON.stringify(this.state));
            this.props.onSubmitOrder(shipInfo);
        }
    };

    private initRegionList = async (commodity: any) => {
        const { data } = await this.getRegionList(commodity?.distributionModeArr?.[0]);
        this.setState({ region: data }, () => {
            if (data?.[0]?.level >= 0) {
                this.regionChangeHandler(data?.[0]?.value, data?.[0]?.level);
            }
        });
    };

    triggerAddToCartFbq = () => {
        //是否已经触发过添加购物车的fbq
        let triggeredAddToCart = sessionStorage.getItem('triggeredAddToCart') === 'true';
        if (triggeredAddToCart) {
            return;
        }
        const { config, commodity } = this.props;
        const { selectedPackage } = this.props;
        sessionStorage.setItem('triggeredAddToCart', 'true');
        //FB像素处理
        if (config.hasFbPixel) {
            if (window.fbq) {
                try {
                    window.fbq?.('track', 'AddToCart', { value: selectedPackage.price, currency: commodity.currencyCode });
                    // logger.debug('fbq:正常调用fbq:track:AddToCart');
                } catch (error) {
                    // logger.error('fbq:调用fbq:track:AddToCart时，接口报错', error);
                }
            } else {
                // logger.warn(
                //     'fbq:有设置fbq，但是在window全局里无法获取fbq对象，发生这个问题，是因为引入的脚本有问题或者脚本无法被加载',
                // );
            }
        }
        if (config.hasTiktokAds) {
            if (window.ttq) {
                try {
                    window.ttq.track('AddToCart');
                } catch (error) {}
            }
        }
        if (config.hasLineAds) {
            if (window._lt) {
                try {
                    if (config.adsConfig.line?.tagId) {
                        window._lt('send', 'cv', { type: 'AddToCart' }, [config.adsConfig.line?.tagId]);
                    }
                } catch (error) {}
            }
        }
        if (config.hasGoogleAds) {
            if (window.gtag) {
                try {
                    if (config.adsConfig.google.conversionLabelAddToCart) {
                        window.gtag('event', 'conversion', {
                            send_to:
                                'AW-' +
                                config.adsConfig.google.conversionId +
                                '/' +
                                config.adsConfig.google.conversionLabelAddToCart,
                            value: selectedPackage.price,
                            currency: commodity.currencyCode,
                        });
                    }
                } catch (error) {}
            }
        }
    };

    componentDidMount() {
        const { commodity } = this.props;
        if (!commodity.id) {
            return;
        }
        this.initRegionList(commodity);
    }

    componentDidUpdate(preProps: Prop, preState: State) {
        const { commodity: oldCommodity } = preProps;
        const { commodity: newCommodity } = this.props;

        if (oldCommodity?.id !== newCommodity?.id && newCommodity?.id) {
            this.initRegionList(newCommodity);
        }
    }

    render() {
        const { config, commodity, intl } = this.props;
        const { selectedDistribution, selectedRegion } = this.state;
        return (
            <div className="akmall-box akmall-box-params">
                <div className="akmall-rows clearfix rows-id-payment">
                    <label className="rows-head">
                        <FormattedMessage id="pay.method" />
                        <span className="akmall-request ">*</span>
                    </label>
                    <div className="rows-params">
                        <div className="akmall-payment clearfix">
                            <label className="ellipsis akmall-params payment-cod active">
                                <input type="radio" name="payment" value="payOnDelivery" />
                                <FormattedMessage id="cod" />
                            </label>
                        </div>
                    </div>
                </div>
                <div className="akmall-rows clearfix rows-id-payment">
                    <label className="rows-head">
                        <FormattedMessage id="delivery.method" />
                        <span className="akmall-request ">*</span>
                    </label>
                    <div className="rows-params">
                        <div className="akmall-payment clearfix">
                            {commodity?.distributionModeArr?.map((dis: any) => {
                                return (
                                    <label
                                        className={`ellipsis akmall-params ${this.distributionModeStyle[dis.value]} payment-cod ${
                                            selectedDistribution.value === dis.value ? 'active' : ''
                                        }`}
                                        key={dis.label}
                                        onClick={this.changeDistributionHandler.bind(this, dis)}
                                    >
                                        <input
                                            type="radio"
                                            name="payment"
                                            value="payOnDelivery"
                                            defaultChecked={selectedDistribution.value === dis.value}
                                        />
                                        {dis.label}
                                    </label>
                                );
                            })}
                        </div>
                        <div id="akmall-payment-info" className="akmall-alert clearfix">
                            <div className="payment-info">
                                <FormattedMessage id="delivery-tip" />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="akmall-rows clearfix rows-id-name">
                    <label className="rows-head">
                        <FormattedMessage id="ship-info.name" />
                        <span className="akmall-request ">*</span>
                    </label>
                    <div className="rows-params">
                        <input
                            type="text"
                            name="name"
                            maxLength={100}
                            placeholder={intl?.formatMessage({ id: 'input.name' })}
                            className="akmall-input-text"
                            defaultValue=""
                            onBlur={event => {
                                this.changeHandler({ realName: event.target.value });
                                this.triggerAddToCartFbq();
                            }}
                        />
                    </div>
                </div>
                <div className="akmall-rows clearfix rows-id-mobile">
                    <label className="rows-head">
                        <FormattedMessage id="ship-info.phone" />
                        <span className="akmall-request ">*</span>
                    </label>
                    <div className="rows-params">
                        <input
                            type="tel"
                            name="mobile"
                            maxLength={100}
                            placeholder={
                                intl?.formatMessage({ id: 'input.phone' }) +
                                (commodity.regionCode == 'TWN' ? '(格式：09XXXXXXXX)' : '')
                            }
                            className="akmall-input-text"
                            defaultValue=""
                            onBlur={event => {
                                this.changeHandler({ phone: event.target.value });
                            }}
                        />
                    </div>
                </div>
                {this.state.region?.length > 0 && (
                    <div className="akmall-rows clearfix rows-id-region">
                        <label className="rows-head">
                            <FormattedMessage id="ship-info.select-area" />
                            <span className="akmall-request">*</span>
                        </label>
                        <div className="rows-params">
                            <div className="region-select region-payOnDelivery">
                                <select
                                    name="region[province]"
                                    id="province"
                                    className="akmall-region akmall-region-province"
                                    onChange={e => {
                                        this.regionChangeHandler(e.target.value, 1);
                                    }}
                                >
                                    {this.state.region.map(reg => {
                                        return (
                                            <option key={reg.value} value={reg.value}>
                                                {reg.label}
                                            </option>
                                        );
                                    })}
                                </select>
                                {this.state?.selectedRegion?.map((reg: any, index: number) => {
                                    if (!reg?.children?.length) {
                                        return null;
                                    }
                                    return (
                                        <select
                                            key={reg.value + index}
                                            style={{ maxWidth: '49%' }}
                                            name="region[city]"
                                            className="akmall-region akmall-region-city"
                                            onChange={e => {
                                                this.regionChangeHandler(e.target.value, index + 2);
                                            }}
                                        >
                                            {reg?.children.map((childReg: any) => {
                                                return (
                                                    <option key={childReg.value} value={childReg.value}>
                                                        {childReg.label}
                                                    </option>
                                                );
                                            })}
                                        </select>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                )}
                <div className="akmall-rows clearfix rows-id-address">
                    <label className="rows-head">
                        <FormattedMessage id="ship-info.detail-address" />
                        <span className="akmall-request ">*</span>
                    </label>
                    <label>{selectedRegion.map(reg => (reg.value ? reg.label : '')).join(' ')}</label>
                    <span style={{ color: '#f60' }}>
                        {this.state.remoteLogisticsFee > 0
                            ? '(' +
                              intl?.formatMessage({ id: 'ship-info.remote_regions_fee' }) +
                              ':' +
                              this.state.remoteLogisticsFee +
                              ')'
                            : ''}
                    </span>
                    <div className="rows-params">
                        {!selectedDistribution.hideAddressInput && (
                            <div className="region-select region-payOnDelivery">
                                <textarea
                                    rows={2}
                                    id="address"
                                    name="address"
                                    maxLength={300}
                                    placeholder={intl?.formatMessage({ id: 'input.detail-address' })}
                                    className="akmall-input-text"
                                    onBlur={event => {
                                        this.changeHandler({ detailAddress: event.target.value });
                                    }}
                                ></textarea>
                            </div>
                        )}
                    </div>
                </div>
                <div className="akmall-rows clearfix rows-id-post">
                    <label className="rows-head">
                        <FormattedMessage id="zip-code" />
                        {config?.requiredConfig?.shipZipCode?.required && <span className="akmall-request">*</span>}
                    </label>
                    <div className="rows-params">
                        <input
                            type="text"
                            name="post"
                            maxLength={64}
                            placeholder={intl?.formatMessage({ id: 'input.zip-code' })}
                            akmall-request=""
                            className="akmall-input-text"
                            defaultValue=""
                            onBlur={event => {
                                this.changeHandler({ shipZipCode: event.target.value });
                            }}
                        />
                    </div>
                </div>
                <div className="akmall-rows clearfix rows-id-mail">
                    <label className="rows-head">
                        <FormattedMessage id="email" />
                        {config?.requiredConfig?.shipEmail?.required && <span className="akmall-request">*</span>}
                    </label>
                    <div className="rows-params">
                        <input
                            type="text"
                            name="mail"
                            maxLength={100}
                            placeholder={intl?.formatMessage({ id: 'input.email' })}
                            akmall-request=""
                            className="akmall-input-text"
                            defaultValue=""
                            onBlur={event => {
                                this.changeHandler({ email: event.target.value });
                            }}
                        />
                    </div>
                </div>
                <div className="akmall-rows clearfix rows-id-remark">
                    <label className="rows-head">
                        <FormattedMessage id="ship-info.message" />
                    </label>
                    <div className="rows-params">
                        <textarea
                            name="remark"
                            maxLength={300}
                            placeholder={intl?.formatMessage({ id: 'input.message' })}
                            className="akmall-input-text"
                            akmall-request=""
                            rows={2}
                            onBlur={event => {
                                this.changeHandler({ remark: event.target.value });
                            }}
                        ></textarea>
                    </div>
                </div>
                <div className="akmall-rows akmall-id-btn clearfix fixed-bottom">
                    <input
                        type="submit"
                        id="akmall-submit"
                        className="akmall-btn akmall-submit"
                        value={intl?.formatMessage({ id: 'confirm-submit' })}
                        onClick={this.submitOrderHandler}
                    />
                </div>
            </div>
        );
    }
}
