/* eslint-disable */
/* tslint:disable */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'src/decorator/inject-intl';
import Base from 'src/core/base';
import { getUserInstruction } from 'src/api/index';
import Image from 'src/components/image';
import { BusinessEvent, UserAction } from 'src/common/constant';
import UserActionDataModel from 'src/model/user-action-data-model';
import UserActionExtraModel from 'src/model/user-action-extra-model';

/** 返回按钮图标 */
const ICON_BACK = require('src/assets/images/icon-goback.svg');
/** 首页图标 */
const ICON_HOME = require('src/assets/images/icon-home.svg');

interface Prop {}
interface State {
    title: string;
    content: string;
}

@injectIntl()
export default class Provision extends Base<Prop, State> {
    constructor(props: any) {
        super(props);
        this.state = { title: '', content: '' };
    }

    componentDidMount() {
        const { history } = this.props;
        const state: any = this.props?.location?.state;
        if (!state?.title) {
            history?.push('/cod/');
            return;
        }
        this.setState(state);
    }
    render() {
        const { history } = this.props;
        const { title, content } = this.state;
        return (
            <>
                <div className="header">
                    <span className="back">
                        <a href="javascript:history.go(-2)" style={{ color: 'white' }}>
                            <img src={ICON_BACK} />
                            <FormattedMessage id="go-back" />
                        </a>
                    </span>
                    <div style={{ width: '80%', display: 'inline-block', textAlign: 'center' }}>
                        <a className="logo" href="#">
                            <h4 style={{ color: 'white' }}>{title}</h4>
                        </a>
                    </div>
                </div>
                <div className="newmain background">
                    <dl className="search_form">
                        <div className="clear query_result search_result" dangerouslySetInnerHTML={{ __html: content }}></div>
                    </dl>
                </div>
                <div className="newmain footer">
                    <div className="clear ptop"></div>

                    <div className="nav2">
                        <div style={{ width: '100%' }}>
                            <a
                                href="#"
                                onClick={() => {
                                    history?.push('/cod/');
                                }}
                            >
                                <span>
                                    <img src={ICON_HOME} />
                                </span>
                                <p>
                                    <FormattedMessage id="go-back" />
                                </p>
                            </a>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}
