/* eslint-disable */
/* tslint:disable */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'src/decorator/inject-intl';
import Base from 'src/core/base';
import Image from 'src/components/image';
import { DEFAULT_UPLOAD_IMAGE, BusinessEvent } from 'src/common/constant';
import { uploadImage } from 'src/api';
import EventBusManager from 'src/core/event-bus-manager';

interface Prop {
    commodity: any;
    selectedPackage: any;
    onSpuAttrChange: (spuAttr: any) => void;
}
interface State {
    selectedSpuAttr: any[];
}

@injectIntl()
export default class SpuAttr extends Base<Prop, State> {
    /** SPU属性各类型的渲染器 */
    private spuAttrTypeRender: any = {};

    constructor(props: any) {
        super(props);
        this.state = {
            selectedSpuAttr: [],
        };
        this.spuAttrTypeRender = {
            RADIO: this.renderRadioSpuAttr,
            TEXT: this.renderTextSpuAttr,
            IMAGE: this.renderImageSpuAttr,
        };
    }

    initSelectedSpuAttr = async (selectedPackage: any, commodity: any) => {
        if (!selectedPackage?.name || !commodity.id) {
            return;
        }
        selectedPackage = JSON.parse(JSON.stringify(selectedPackage));
        const selectedSpuAttr: any[] = [];
        selectedPackage?.spuArr?.forEach((spuItem: any, spuIndex: number) => {
            const product: any = {
                spuName: spuItem.name,
                skuArr: [],
            };
            let { spuArr } = commodity;
            spuArr = spuArr.filter((spu: any) => {
                return spu.name === spuItem.name;
            });
            spuArr = spuArr[0];
            for (let i = 0; i < spuItem.amount; i++) {
                const attrs: any[] = [];
                spuArr?.attributeArr?.forEach((attr: any) => {
                    attrs.push({
                        attributeName: attr.name,
                        attributeValueLabel: attr.type === 'IMAGE' || attr.type === 'TEXT' ? null : attr.valueArr[0].label,
                    });
                });
                product.skuArr.push({
                    attributeValueArr: attrs,
                    qty: 1,
                });
            }
            selectedSpuAttr.push(product);
        });
        this.setState({ selectedSpuAttr });
        this.props.onSpuAttrChange(selectedSpuAttr);
    };

    skuChangeHandler = (spuIndex: number, productIndex: number, skuIndex: number, valueLabel: string) => {
        const { selectedSpuAttr } = this.state;
        selectedSpuAttr[spuIndex].skuArr[productIndex].attributeValueArr[skuIndex].attributeValueLabel = valueLabel;
        this.setState({ selectedSpuAttr });
        this.props.onSpuAttrChange(selectedSpuAttr);
    };

    /**
     * 属性是文本框的处理器
     * @param e
     * @param index
     */
    attrInputChangeHandler = (e: any, index: { spuIndex: number; productIndex: number; skuIndex: number }) => {
        this.skuChangeHandler(index.spuIndex, index.productIndex, index.skuIndex, e.target.value);
    };

    /**
     * 上传图片
     */
    uploadImageHandler = async (e: any, index: { spuIndex: number; productIndex: number; skuIndex: number }) => {
        const commodity = JSON.parse(sessionStorage.getItem('commodity') as string);
        const f = e.target;
        let param = new FormData();
        param.append('file', f.files[0]); //通过append向form对象添加数据
        param.append('commodityId', commodity.id);
        console.log(param.get('file')); //FormData私有类对象，访问不到，可以通过get判断值是否传进去
        let config = {
            headers: { 'Content-Type': 'multipart/form-data' },
        }; //添加请求头
        const response = await uploadImage(param, config);
        this.skuChangeHandler(index.spuIndex, index.productIndex, index.skuIndex, response.data.imgUrl);
        $(f.previousElementSibling).attr('src', response.data.imgUrl + '!thn90-90');
    };

    /** 渲染单选框属性 */
    renderRadioSpuAttr = (data: { spuAttr: any; attrValue: any; spuIndex: number; productIndex: number; skuIndex: number }) => {
        const { spuAttr, attrValue, spuIndex, productIndex, skuIndex } = data;
        return (
            <label
                key={attrValue.label}
                className={`akmall-group akmall-params akmall-radio akmall-params-image ${
                    this.state.selectedSpuAttr?.[spuIndex]?.skuArr[productIndex]?.attributeValueArr[skuIndex]
                        ?.attributeValueLabel === attrValue.label
                        ? 'active'
                        : ''
                }`}
                onClick={this.skuChangeHandler.bind(this, spuIndex, productIndex, skuIndex, attrValue.label)}
            >
                {attrValue.imgUrl && (
                    <p className="item-image">
                        <Image src={attrValue.imgUrl} />
                    </p>
                )}
                <span className="akmall-group-box">
                    <input type={spuAttr?.type?.toLocaleLowerCase()} defaultValue={attrValue.label} />
                    <label className="selected-icon"> </label>
                </span>
                {attrValue.label}
            </label>
        );
    };
    /** 渲染输入框属性 */
    renderTextSpuAttr = (data: any) => {
        const { spuAttr, attrValue, spuIndex, productIndex, skuIndex } = data;
        return (
            <label
                key={attrValue.label}
                className={`akmall-group akmall-params akmall-radio akmall-params-image ${
                    this.state.selectedSpuAttr?.[spuIndex]?.skuArr[productIndex]?.attributeValueArr[skuIndex]
                        ?.attributeValueLabel === attrValue.label
                        ? 'active'
                        : ''
                }`}
                onClick={this.skuChangeHandler.bind(this, spuIndex, productIndex, skuIndex, attrValue.label)}
            >
                {attrValue.imgUrl && (
                    <p className="item-image">
                        <img src={attrValue.imgUrl} />
                    </p>
                )}
                <span className="akmall-group-box">
                    <input
                        type={spuAttr?.type?.toLocaleLowerCase()}
                        defaultValue={attrValue.label}
                        onChange={e => {
                            this.attrInputChangeHandler(e, {
                                spuIndex,
                                productIndex,
                                skuIndex,
                            });
                        }}
                    />
                    <label className="selected-icon"> </label>
                </span>
                {attrValue.label}
            </label>
        );
    };
    /** 渲染图片属性 */
    renderImageSpuAttr = (data: any) => {
        const { spuAttr, attrValue, spuIndex, productIndex, skuIndex } = data;
        return (
            <label
                key={attrValue.label}
                className={`akmall-group akmall-params akmall-radio akmall-params-image ${
                    this.state.selectedSpuAttr?.[spuIndex]?.skuArr[productIndex]?.attributeValueArr[skuIndex]
                        ?.attributeValueLabel === attrValue.label
                        ? 'active'
                        : ''
                }`}
                onClick={this.skuChangeHandler.bind(this, spuIndex, productIndex, skuIndex, attrValue.label)}
            >
                <span className="akmall-group-box">
                    <img src={DEFAULT_UPLOAD_IMAGE} alt="" />
                    <input
                        type="file"
                        onChange={e =>
                            this.uploadImageHandler(e, {
                                spuIndex,
                                productIndex,
                                skuIndex,
                            })
                        }
                    />
                    <label className="selected-icon"> </label>
                </span>
                {attrValue.label}
            </label>
        );
    };

    renderSpuAttr = () => {
        const items = [];
        const { selectedPackage, commodity } = this.props;
        for (let i = 0, iLen = selectedPackage?.spuArr?.length ?? 0; i < iLen; i++) {
            const spuName = selectedPackage.spuArr[i].name;
            const amount = selectedPackage.spuArr[i].amount;
            let spuAttrArr = commodity.spuArr.filter((spu: any) => {
                return spuName === spu.name;
            });
            let sizeImgUrl = spuAttrArr[0].sizeImgUrl;
            spuAttrArr = spuAttrArr[0].attributeArr;
            for (let j = 0; j < amount; j++) {
                if (!spuAttrArr?.length) {
                    continue;
                }
                items.push(
                    <div key={`spu-attr-${i}+${j}`}>
                        {spuAttrArr?.map((spuAttr: any, skuIndex: number) => {
                            return (
                                <div
                                    className="akmall-rows clearfix rows-id-extends extends-level-akmall-radio"
                                    key={`skuIndex${skuIndex}`}
                                >
                                    <label className="rows-head">
                                        {spuAttr.name}
                                        <span className="akmall-request">*</span>
                                    </label>
                                    <div className="rows-params">
                                        {spuAttr?.valueArr?.map((val: any, valIndex: number) => {
                                            return this.spuAttrTypeRender[spuAttr?.type].call(this, {
                                                spuAttr: spuAttr,
                                                attrValue: val,
                                                spuIndex: i,
                                                productIndex: j,
                                                skuIndex: skuIndex,
                                            });
                                        })}
                                    </div>
                                </div>
                            );
                        })}
                        {j == 0 && !!sizeImgUrl && (
                            <div className="rows-params">
                                <img src={sizeImgUrl} alt="" style={{ width: '100%' }} />
                            </div>
                        )}
                    </div>,
                );
            }
        }
        return items;
    };

    componentWillReceiveProps(nextProps: Prop) {
        const { commodity: oldCommodity, selectedPackage: oldSelectedPackage } = this.props;
        const { commodity: newCommodity, selectedPackage: newSelectedPackage } = nextProps;
        if (oldSelectedPackage?.name !== newSelectedPackage?.name || oldCommodity?.id !== newCommodity?.id) {
            this.initSelectedSpuAttr(newSelectedPackage, newCommodity);
        }
    }
    componentDidMount() {
        const { selectedPackage, commodity } = this.props;
        selectedPackage.name && this.initSelectedSpuAttr(selectedPackage, commodity);
    }

    render() {
        return <div className="akmall-box">{this.renderSpuAttr()}</div>;
    }
}
