/* eslint-disable */
/* tslint:disable */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'src/decorator/inject-intl';
import Base from 'src/core/base';
import Toast from 'src/components/toast';
import { getUserInstruction, createOrder } from 'src/api/index';
import { BusinessEvent, UserAction } from 'src/common/constant';
import UserActionDataModel from 'src/model/user-action-data-model';
import UserActionExtraModel from 'src/model/user-action-extra-model';
import Header from './components/header';
import Description from './components/description';
import OrderQuery from './components/order-query';
import Logger from 'src/utils/logger';

const logger = new Logger('Detail');

interface Prop {}
interface State {
    commodity: any;
    config: any;
    selectedPackage: any;
    selectedSpuAttr: any;
    showOrderCart: boolean;
    showQueryOrder: boolean;
    region: any;
}

@injectIntl()
export default class Detail extends Base<Prop, State> {
    private userProvision: any = null;
    private orderId: string | null = null;
    /** 是否正在静默提交中 */
    private silentSubmitting: boolean = false;
    /** 是否已经静默提交过 */
    private silentSubmitted: boolean = false;
    /** 是否提交了完成注册的FBQ */
    private completeRegistrationFbqSubmitted: boolean = false;
    private ubBrowseToBottomSubmitted: boolean = false;
    private zipCodeRegExp: RegExp = /^\d+-?\d*$/;
    private phoneRegExp: RegExp = /^09[0-9]{8}$/;
    private mailRegExp: RegExp =
        /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    constructor(props: any) {
        super(props);
        this.state = {
            commodity: {},
            config: {},
            selectedPackage: {},
            selectedSpuAttr: [],
            showOrderCart: true,
            showQueryOrder: false,
            region: { remoteLogisticsFee: 0 },
        };
        this.registerEvent();
    }

    private scrollHandler = (data: any) => {
        this.setState({ showOrderCart: data?.offsetTop > 0 });
    };

    private registerEvent = () => {
        this.addEventListener(BusinessEvent.SCROLL_TO_ORDER, this.scrollHandler);
    };

    private removeEvent = () => {
        this.removeEventListener(BusinessEvent.SCROLL_TO_ORDER, this.scrollHandler);
    };

    private iniCommodity = async () => {
        const commodity = JSON.parse(sessionStorage.getItem('commodity') as string);

        if (!commodity) {
            return;
        }

        const config = JSON.parse(sessionStorage.getItem('config') as string);
        if (!config) {
            return;
        }

        const userActionData: UserActionDataModel = {
            promoteId: config.promoteId,
            commodityId: commodity.id,
            random: commodity.random,
            promoteFrom: document.referrer,
        };
        const userActionExtra: UserActionExtraModel = {
            type: UserAction.ENTRY_COMMODITY_PAGE,
        };
        this.dispatch(BusinessEvent.USER_ACTION, userActionData, userActionExtra);

        if (!this.userProvision && commodity.noticeId) {
            const { data } = await getUserInstruction(commodity.noticeId);
            this.userProvision = data;
        }
        this.setState({
            commodity,
            config,
        });
    };

    initScrollHandler = () => {
        $(window).on('scroll', () => {
            const scrollTop = $(window).scrollTop();
            const scrollHeight = $(document).height();
            const windowHeight = $(window).height();
            if (scrollTop + windowHeight === scrollHeight) {
                //已提交，不再提交
                if (this.ubBrowseToBottomSubmitted) {
                    return;
                }
                this.ubBrowseToBottomSubmitted = true;
                const { commodity, config } = this.state;
                const userActionData: UserActionDataModel = {
                    promoteId: config.promoteId,
                    commodityId: commodity.id,
                    random: commodity.random,
                };
                const userActionExtra: UserActionExtraModel = {
                    type: UserAction.BROWSE_TO_BOTTOM,
                };
                this.dispatch(BusinessEvent.USER_ACTION, userActionData, userActionExtra);
            }
        });
    };

    silentSubmitValidate = (shipInfo: any) => {
        const { realName, phone, email, shipZipCode, selectedRegion, detailAddress, selectedDistribution } = shipInfo;
        const { config, commodity } = this.state;

        //用户名和手机必填
        if (!realName?.trim() || !phone?.trim()) {
            return false;
        }

        if (commodity.regionCode == 'TWN' && phone && !this.phoneRegExp.test(phone)) {
            return false;
        }
        //下拉框有多个，并且最后一个下拉框没有选中
        if (selectedRegion?.length > 0 && selectedRegion[selectedRegion.length - 1]?.children?.length > 0) {
            return false;
        }
        let noSelected = false;
        for (let i = 0, iLen = selectedRegion?.length; i < iLen; i++) {
            const reg = selectedRegion[i];
            if (!reg.value?.trim()) {
                noSelected = true;
                break;
            }
        }
        if (noSelected) {
            return false;
        }

        //没有填写详细地址
        if (!detailAddress?.trim()) {
            //显示详细地址框
            if (!selectedDistribution.hideAddressInput) {
                return false;
            }
            //没有下拉选中地址
            if (!selectedRegion?.length) {
                return false;
            }
        }

        if (config?.requiredConfig?.shipEmail?.required && !email) {
            return false;
        }

        if (config?.requiredConfig?.shipZipCode?.required && !shipZipCode) {
            return false;
        }

        if (config?.requiredConfig?.shipEmail?.required && email && !this.mailRegExp.test(email)) {
            return false;
        }

        if (config?.requiredConfig?.shipZipCode?.required && shipZipCode && !this.zipCodeRegExp.test(shipZipCode)) {
            return false;
        }

        return true;
    };

    packageChangeHandler = async (pkg: any) => {
        this.setState({ selectedPackage: pkg });
    };

    spuAttrChangeHandler = async (spuAttr: any) => {
        this.setState({ selectedSpuAttr: spuAttr });
    };

    /** 邮寄信息变更 */
    shipInfoChangeHandler = async (shipInfo: any) => {
        const { config } = this.state;
        const result = this.silentSubmitValidate(shipInfo);
        // if (config?.autoSubmit && result) {
        //     this.silentSubmitOrder(shipInfo);
        // }
        if (result) {
            this.triggerCompleteRegistration(shipInfo);
        }
    };

    onRemoteRegionHandler = (region: any) => {
        this.setState({ region });
    };

    triggerCompleteRegistration = (shipInfo: any) => {
        const { commodity, selectedPackage, config } = this.state;
        //已提交过完成注册的fbq，不再提交
        if (this.completeRegistrationFbqSubmitted) {
            return;
        }
        this.completeRegistrationFbqSubmitted = true;
        if (config.hasFbPixel) {
            if (window.fbq) {
                try {
                    window.fbq('track', 'CompleteRegistration', {
                        value: selectedPackage.price,
                        currency: commodity.currencyCode,
                    });
                } catch (error) {}
            }
        }
        if (config.hasTiktokAds) {
            if (window.ttq) {
                try {
                    window.ttq.track('CompleteRegistration');
                } catch (error) {}
            }
        }
        if (config.hasGoogleAds) {
            if (window.gtag) {
                try {
                    if (config.adsConfig.google.conversionLabelCompleteRegistration) {
                        window.gtag('event', 'conversion', {
                            send_to:
                                'AW-' +
                                config.adsConfig.google.conversionId +
                                '/' +
                                config.adsConfig.google.conversionLabelCompleteRegistration,
                            value: selectedPackage.price + selectedPackage.logisticsFee,
                            currency: commodity.currencyCode,
                        });
                    }
                } catch (error) {}
            }
        }

        this.ubFillShipInfo();
        this.ubAutoSubmitOrderInfo(shipInfo);
    };

    triggerPurchaseAds = () => {
        const { commodity, selectedPackage, config } = this.state;
        logger.debug('FB像素是否开启。%s', config?.hasFbPixel);

        if (config.hasFbPixel) {
            if (window.fbq) {
                try {
                    window.fbq?.('track', 'Purchase', { value: selectedPackage.price, currency: commodity.currencyCode });
                } catch (error) {}
            }
        }
        if (config.hasTiktokAds) {
            if (window.ttq) {
                try {
                    window.ttq.track('PlaceAnOrder');
                    window.ttq.track('CompletePayment');
                } catch (error) {}
            }
        }
        if (config.hasLineAds) {
            if (window._lt) {
                try {
                    if (config.adsConfig.line?.tagId) {
                        window._lt(
                            'send',
                            'cv',
                            {
                                type: 'Purchase',
                            },
                            [config.adsConfig.line?.tagId],
                        );
                        window._lt(
                            'send',
                            'cv',
                            {
                                type: 'Conversion',
                            },
                            [config.adsConfig.line?.tagId],
                        );
                    }
                } catch (error) {}
            }
        }
        if (config.hasGoogleAds) {
            if (window.gtag) {
                try {
                    if (config.adsConfig.google.conversionLabelPurchase) {
                        window.gtag('event', 'conversion', {
                            send_to:
                                'AW-' +
                                config.adsConfig.google.conversionId +
                                '/' +
                                config.adsConfig.google.conversionLabelPurchase,
                            value: selectedPackage.price + selectedPackage.logisticsFee,
                            currency: commodity.currencyCode,
                        });
                    }
                } catch (error) {}
            }
        }
    };

    ubSubmitOrder = async (orderId: string) => {
        //用户行为(提交订单)处理
        const { commodity } = this.state;
        const userActionData: UserActionDataModel = {
            promoteId: commodity.promoteId,
            commodityId: commodity.id,
            random: commodity.random,
            orderId,
        };
        const userActionExtra: UserActionExtraModel = {
            type: UserAction.SUBMIT_ORDER,
        };
        this.dispatch(BusinessEvent.USER_ACTION, userActionData, userActionExtra);
    };

    ubFillShipInfo = async () => {
        //用户行为(填写收件信息)处理
        const { commodity } = this.state;
        const userActionData: UserActionDataModel = {
            promoteId: commodity.promoteId,
            commodityId: commodity.id,
            random: commodity.random,
        };
        const userActionExtra: UserActionExtraModel = {
            type: UserAction.FILL_SHIP_INFO,
        };
        this.dispatch(BusinessEvent.USER_ACTION, userActionData, userActionExtra);
    };

    ubAutoSubmitOrderInfo = async (shipInfo: any) => {
        //用户行为(自动提交订单)处理
        const { commodity, selectedPackage, selectedSpuAttr, config, region } = this.state;
        let shipHouse = '';
        for (let i = 3, iLen = shipInfo?.selectedRegion?.length; i < iLen; i++) {
            const reg = shipInfo?.selectedRegion[i];
            shipHouse += reg.value;
        }
        shipHouse += shipInfo.detailAddress;
        const orderInfo: any = {
            promoteId: commodity?.promoteId, // 推广id
            commodityId: commodity?.id,
            commodityName: this.state.commodity.name,
            commodityVer: commodity?.ver, // 商品版本,商品详细里返回的，提交订单倒提交
            comboId: selectedPackage?.id, //套餐id
            comboName: selectedPackage?.name, //套餐名称
            price: selectedPackage.price + selectedPackage.logisticsFee,
            currencyCode: commodity.currencyCode,
            qty: 1,
            shipNickname: shipInfo?.realName,
            shipPhone: shipInfo?.phone,
            shipEmail: shipInfo?.email,
            shipState: shipInfo?.selectedRegion?.[0]?.value ?? '',
            shipCity: shipInfo?.selectedRegion?.[1]?.value ?? '',
            shipStreet: shipInfo?.selectedRegion?.[2]?.value ?? '',
            shipHouse: shipHouse,
            distributionMode: shipInfo?.selectedDistribution,
            distributionModeCode: shipInfo.selectedDistribution.value,
            shipZipCode: shipInfo?.shipZipCode,
            message: shipInfo?.remark,
            token: sessionStorage.getItem('token'),
            regionId: region.remoteLogisticsFee > 0 ? region.regionId : '',
        };
        orderInfo.spuArr = selectedSpuAttr;
        const userActionData: UserActionDataModel = {
            promoteId: commodity.promoteId,
            commodityId: commodity.id,
            random: commodity.random,
            orderInfo,
        };
        const userActionExtra: UserActionExtraModel = {
            type: UserAction.AUTO_SUBMIT_ORDER,
        };
        this.dispatch(BusinessEvent.USER_ACTION, userActionData, userActionExtra);
    };

    /** 主动提交订单 */
    submitOrderHandler = async (shipInfo: any) => {
        let shipHouse = '';
        for (let i = 3, iLen = shipInfo?.selectedRegion?.length; i < iLen; i++) {
            const reg = shipInfo?.selectedRegion[i];
            shipHouse += reg.value;
        }
        shipHouse += shipInfo.detailAddress;

        const order: any = {
            id: this.orderId,
            promoteId: this.state.commodity.promoteId, // 推广id
            commodityId: this.state.commodity.id,
            commodityVer: this.state.commodity.ver, // 商品版本,商品详细里返回的，提交订单倒提交
            comboId: this.state.selectedPackage.id, //套餐id
            qty: 1,
            shipNickname: shipInfo.realName,
            shipPhone: shipInfo.phone,
            shipEmail: shipInfo.email,
            shipState: shipInfo.selectedRegion?.[0]?.value ?? '',
            shipCity: shipInfo.selectedRegion?.[1]?.value ?? '',
            shipStreet: shipInfo.selectedRegion?.[2]?.value ?? '',
            shipHouse: shipHouse,
            distributionMode: shipInfo.selectedDistribution,
            distributionModeCode: shipInfo.selectedDistribution.value,
            shipZipCode: shipInfo.shipZipCode,
            message: shipInfo.remark,
            customerApproved: true,
            token: sessionStorage.getItem('token'),
            regionId: this.state.region.remoteLogisticsFee > 0 ? this.state.region.regionId : '',
        };
        order.spuArr = this.state.selectedSpuAttr;
        const { data } = await createOrder(order).catch();
        const { commodity } = this.state;
        this.orderId = data.orderId;

        this.triggerPurchaseAds();
        this.ubSubmitOrder(data.orderId);

        this.props.history?.push({
            pathname: `/cod/order-confirm/${data.orderId}`,
        });
        this.orderId = null;
    };

    initUserAction = () => {
        //用户行为(加入购物车,进入下单页)处理
        const { commodity } = this.state;
        const userActionData: UserActionDataModel = {
            promoteId: commodity.promoteId,
            commodityId: commodity.id,
            random: commodity.random,
        };
        const userActionExtra: UserActionExtraModel = {
            type: UserAction.ADD_TO_CART,
        };
        this.dispatch(BusinessEvent.USER_ACTION, userActionData, userActionExtra);
    };

    buyHandler = () => {
        this.triggerAddToCartFbq();
        this.initUserAction();
    };
    triggerAddToCartFbq = () => {
        //是否已经触发过添加购物车的fbq
        let triggeredAddToCart = sessionStorage.getItem('triggeredAddToCart') === 'true';
        if (triggeredAddToCart) {
            return;
        }
        const { config, commodity, selectedPackage } = this.state;
        sessionStorage.setItem('triggeredAddToCart', 'true');
        //FB像素处理
        if (config.hasFbPixel) {
            if (window.fbq) {
                try {
                    window.fbq?.('track', 'AddToCart', { value: selectedPackage.price, currency: commodity.currencyCode });
                } catch (error) {}
            }
        }
        if (config.hasTiktokAds) {
            if (window.ttq) {
                try {
                    window.ttq.track('AddToCart');
                } catch (error) {}
            }
        }
        if (config.hasLineAds) {
            if (window._lt) {
                try {
                    if (config.adsConfig.line?.tagId) {
                        window._lt('send', 'cv', { type: 'AddToCart' }, [config.adsConfig.line?.tagId]);
                    }
                } catch (error) {}
            }
        }
        if (config.hasGoogleAds) {
            if (window.gtag) {
                try {
                    if (config.adsConfig.google.conversionLabelAddToCart) {
                        window.gtag('event', 'conversion', {
                            send_to:
                                'AW-' +
                                config.adsConfig.google.conversionId +
                                '/' +
                                config.adsConfig.google.conversionLabelAddToCart,
                            value: selectedPackage.price,
                            currency: commodity.currencyCode,
                        });
                    }
                } catch (error) {}
            }
        }
    };

    componentDidMount() {
        this.iniCommodity();
        this.initScrollHandler();
    }

    componentWillUnmount() {
        $(window).off('scroll');
        this.removeEvent();
    }
    
    //锚点
    scrollToAnchor = (anchorName: string) => {
      if (anchorName) {
        // 找到锚点
        let anchorElement = document.getElementById(anchorName);
        // 如果对应id的锚点存在，就跳转到锚点
        if (anchorElement) { anchorElement.scrollIntoView({ block: 'start', behavior: 'smooth' }); }
      }
    }

    render() {
        const { history } = this.props;
        const { commodity, config, selectedPackage, showQueryOrder } = this.state;
        return (
            <div className="wrapper akmall-detail-wrap">
                <div className="akmall-page">
                    <Header commodity={commodity} config={config} onBuy={this.buyHandler} />
                    <Description
                        commodity={commodity}
                        config={config}
                        selectedPackage={selectedPackage}
                        onPackageChange={this.packageChangeHandler}
                        onSpuAttrChange={this.spuAttrChangeHandler}
                        onShipInfoChange={this.shipInfoChangeHandler}
                        onSubmitOrder={this.submitOrderHandler}
                        onRemoteRegion={this.onRemoteRegionHandler}
                    />
                </div>
                {showQueryOrder && <OrderQuery commodity={commodity} />}
                <div className="akmall-footer" id="akmall-footer">
                    <div style={{ fontSize: '14px', marginBottom: '20px' }}>
                        {this.userProvision?.itemArr?.map((item: any, index: number) => {
                            return index < this.userProvision.itemArr.length - 1 ? (
                                <>
                                    <a
                                        href="#"
                                        onClick={() => {
                                            history?.push('/cod/provision', item);
                                        }}
                                    >
                                        {item.title}
                                    </a>
                                    &nbsp;|&nbsp;
                                </>
                            ) : (
                                <>
                                    <a
                                        href="#"
                                        onClick={() => {
                                            history?.push('/cod/provision', item);
                                        }}
                                    >
                                        {item.title}
                                    </a>
                                </>
                            );
                        })}
                        {/* <a
                            href="#akmall-footer"
                            onClick={() => {
                                this.setState({ showQueryOrder: true });
                            }}
                        >
                            <FormattedMessage id="query-order" />
                        </a> */}
                    </div>
                    <div className="akmall-remark"></div>
                    {this.state.showOrderCart && (
                        <div className="akmall-foot-nav">
                            <span id="akmallUp"></span>
                            <ul>
                                <li className="foot-nav-3" style={{ width: '20%', height: '47px' }}>
                                    <a
                                        href="#akmall-footer"
                                        onClick={() => {
                                            this.setState({ showQueryOrder: true });
                                        }}
                                    >
                                        <strong>
                                            <FormattedMessage id="query-order" />
                                        </strong>
                                    </a>
                                </li>
                                <li className="foot-nav-3" style={{ width: '80%', height: '47px' }}>
                                    <a href="#akmallOrder" onClick={this.buyHandler}>
                                        <strong className="icon CartButton">
                                            <FormattedMessage id="place-an-order" />
                                        </strong>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    )}
            </div>
            
            <div className="fixedbtn">
              {config.socialType?.split(',').map((socialType:string,index:number) => {
                                    return (
                        socialType == 'FACEBOOK'  ? 
                   
                        <div key='FACEBOOK'
                            className="s_order"
                            style={{
                                background: `url(${require('src/assets/images/FACEBOOK.png')}) center no-repeat`,
                                backgroundSize: '100% auto',
                            }}
                        >
                            <a target="_blank" href={`https://www.m.me/${config.socialNo.split(',')[index]}`}></a>
                        </div>
                   :socialType.toUpperCase() == 'LINE'  ? 
                        <div  key='LINE'
                            className="s_order"
                            style={{
                                background: `url(${require('src/assets/images/LINE1.png')}) center no-repeat`,
                                backgroundSize: '100% auto',
                            }}
                        >
                            <a target="_blank" href={`https://line.me/ti/p/${config.socialNo.split(',')[index]}`}></a>
                        </div>
                     :socialType == 'WHATS_APP'  ? 
                        <div  key='WHATS_APP'
                            className="s_order"
                            style={{
                                background: `url(${require('src/assets/images/WHATS_APP.png')}) center no-repeat`,
                                backgroundSize: '100% auto',
                            }}
                        >
                            <a target="_blank" href={`https://api.whatsapp.com/send?phone=${config.socialNo.split(',')[index]}`}></a>
                        </div>
                     :socialType == 'PHONE'  ? 
                        <div key='PHONE'
                                                className="s_order"
                                                title={config.socialNo.split(',')[index]}
                            style={{
                                background: `url(${require('src/assets/images/PHONE.png')}) center no-repeat`,
                                backgroundSize: '100% auto',
                            }}
                        >
                            <a target="_blank"  href={`tel:${config.socialNo.split(',')[index]}`}></a>
                                              </div>
                                              :socialType == 'EMAIL'  ? 
                                              <div key='EMAIL'
                                                className="s_order"
                                                title={config.socialNo.split(',')[index]}
                            style={{
                                background: `url(${require('src/assets/images/EMAIL.png')}) center no-repeat`,
                                backgroundSize: '100% auto',
                            }}
                        >
                            <a target="_blank"  href={`mailto:${config.socialNo.split(',')[index]}`}></a>
                                                </div>:<div key='none'/>
                                  );
                                })}
                </div>
            </div>
        );
    }
}
