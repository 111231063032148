/* eslint-disable */
/* tslint:disable */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import SwiperCore, { Autoplay } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import { injectIntl } from 'src/decorator/inject-intl';
import Base from 'src/core/base';
import { getQueryParams, loadScriptString } from 'src/utils';
import { getUserInstruction } from 'src/api/index';
import Image from 'src/components/image';
import { BusinessEvent, UserAction } from 'src/common/constant';
import UserActionDataModel from 'src/model/user-action-data-model';
import UserActionExtraModel from 'src/model/user-action-extra-model';

import 'swiper/swiper.scss';

SwiperCore.use([Autoplay]);

interface Prop {
    commodity: any;
    config: any;
    onBuy: () => void;
}
interface State {}

@injectIntl()
export default class Header extends Base<Prop, State> {
    private userInstruction: any = null;
    private swiper: any = null;
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    onBuyHandler = () => {
        const { onBuy } = this.props;
        onBuy?.();
    };

    componentDidMount() {}

    componentDidUpdate(preProps: Prop, preState: State, spanshot: any) {
        const { commodity: oldCommodity } = preProps;
        const { commodity: newCommodity } = this.props;
        if (oldCommodity?.id !== newCommodity?.id && newCommodity?.imgArr?.length > 1) {
            //this.swiper?.update(true);
        }
    }

    render() {
        const { commodity, config } = this.props;
        return (
            <>
                <div className="box box-image">
                    <div className="box-content">
                        <div className="newbanner">
                            <div className="newflexslider">
                                <ul className="newslides">
                                    {!commodity?.imgArr?.length || commodity?.imgArr?.length === 1 ? (
                                        <img
                                            src={commodity?.imgArr?.[0] ? commodity?.imgArr?.[0] : commodity?.mainImg}
                                            className="image"
                                            alt=""
                                        />
                                    ) : (
                                        <Swiper
                                            init={true}
                                            loop={true}
                                            autoplay={{ disableOnInteraction: false, delay: 2 * 1000 }}
                                            onSwiper={swiper => (this.swiper = swiper)}
                                            style={{ width: document.body.clientWidth + 'px', maxWidth: '750px' }}
                                        >
                                            {commodity?.imgArr?.map((img: any) => {
                                                return (
                                                    <SwiperSlide key={img}>
                                                        <img
                                                            src={'https:' + img}
                                                            className="image"
                                                            alt=""
                                                            onLoad={() => {
                                                                this.swiper?.update();
                                                            }}
                                                        />
                                                    </SwiperSlide>
                                                );
                                            })}
                                        </Swiper>
                                    )}
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="box akmallbox-1">
                    <div className="box-content">
                        <div className="akmall-plug clearfix">
                            <div className="price" style={{ width: '100%' }}>
                                {commodity.currencyDisplayPosition === 'BEFORE_AMOUNT' && (
                                    <span className="symbol">{commodity.currencyDisplay}</span>
                                )}
                                <span className="current-price">{commodity?.salePrice}</span>
                                {commodity.currencyDisplayPosition === 'AFTER_AMOUNT' && (
                                    <span className="symbol">{commodity.currencyDisplay}</span>
                                )}
                                {commodity?.originalPrice > 0 && (
                                    <span className="group">
                                        <del className="original-price">
                                            {commodity.currencyDisplayPosition === 'BEFORE_AMOUNT' && commodity.currencyDisplay}
                                            {commodity?.originalPrice}
                                            {commodity.currencyDisplayPosition === 'AFTER_AMOUNT' && commodity.currencyDisplay}
                                        </del>
                                    </span>
                                )}
                            </div>
                        </div>
                        <div className="akmall-content-title">
                            <h1>{commodity?.name}</h1>
                            <p></p>
                        </div>
                        <div className="baoyou">
                            <span className="by">
                                <FormattedMessage id="free-shipping" />
                            </span>
                            <span className="huo">
                                <FormattedMessage id="cod" />
                            </span>
                            <span className="tui">
                                <FormattedMessage id="7-day" />
                            </span>
                        </div>
                        <div className="gou CartButton">
                            <a href="#akmallOrder" onClick={this.onBuyHandler}>
                                <FormattedMessage id="buy-immediately.btn" />
                            </a>
                </div>
                {config.socialType?.split(',').map((socialType:string,index:number) => {
                                    return (
                        socialType.toUpperCase() == 'LINE'  ? 
                            <div className="contact line">
                                <i className="icon-line"></i>
                                <a target="_blank" href={`https://line.me/ti/p/${config.socialNo.split(',')[index]}`}>
                                            <FormattedMessage id="contact-me" />: {config.socialNo.split(',')[index]}
                                </a>
                            </div>: socialType == 'FACEBOOK'  ? 
                            <div className="contact facebook">
                                <i className="icon-messenger"></i>
                                <a target="_blank" href={`https://www.m.me/${config.socialNo.split(',')[index]}`}>
                                    <FormattedMessage id="contact-me" />: {config.socialNo.split(',')[index]}
                                </a>
                            </div>:socialType == 'WHATS_APP'  ? 
                            <div className="contact whatsapp">
                                <i className="icon-whatsapp"></i>
                                <a target="_blank" href={`https://api.whatsapp.com/send?phone=${config.socialNo.split(',')[index]}`}>
                                    <FormattedMessage id="contact-me" />: {config.socialNo.split(',')[index]}
                                </a>
                            </div>:socialType == 'PHONE'  ? 
                            <div className="contact phone">
                                <i className="icon-whatsapp"></i>
                                                <a target="_blank" href={'tel:' + config.socialNo.split(',')[index]} >
                                    <FormattedMessage id="contact-me" />: {config.socialNo.split(',')[index]}
                                </a>
                            </div>:socialType == 'EMAIL'  ? 
                            <div className="contact email">
                                {/* <i className="icon-whatsapp"></i> */}
                                <a target="_blank" href={'mailto:' + config.socialNo.split(',')[index]}>
                                    <FormattedMessage id="contact-me" />: {config.socialNo.split(',')[index]}
                                </a>
                            </div>: <div></div>
                  );
                })}
                    </div>
                </div>
            </>
        );
    }
}
