/* eslint-disable */
/* tslint:disable */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'src/decorator/inject-intl';
import Base from 'src/core/base';
import Image from 'src/components/image';
import { getUserInstruction } from 'src/api/index';
import { BusinessEvent, UserAction } from 'src/common/constant';
import UserActionDataModel from 'src/model/user-action-data-model';
import UserActionExtraModel from 'src/model/user-action-extra-model';
import Packages from '../packages';
import ShipInfo from '../ship-info';

interface Prop {
    commodity: any;
    config: any;
    selectedPackage: any; //选中的套餐
    onPackageChange: (pkg: any) => void;
    onSpuAttrChange: (spuAttr: any) => void;
    onShipInfoChange: (spuAttr: any) => void;
    onSubmitOrder: (shipInfo: any) => void;
    onRemoteRegion: (region: any) => void;
}
interface State {
    region: any;
}

@injectIntl()
export default class BuyInfo extends Base<Prop, State> {
    constructor(prop: Prop) {
        super(prop);
        this.state = { region: { remoteLogisticsFee: 0 } };
        this.initScrollHandler();
    }
    initScrollHandler = () => {
        $(window).on('scroll', () => {
            const offsetTop = $('#akmallOrder').offset().top - $(window).scrollTop();
            this.dispatch(BusinessEvent.SCROLL_TO_ORDER, { offsetTop });
        });
    };

    onRemoteRegionHandler = (region: any) => {
        this.setState({ region });
        this.props.onRemoteRegion(region);
    };

    render() {
        const { commodity, config, selectedPackage } = this.props;
        const { region } = this.state;
        return (
            <div className="akmall-order akmall-border akmall-lang-zh-tw clearfix" id="akmallOrder">
                <div className="akmall-main akmall-border akmall-full-row">
                    <div
                        className="akmall-title akmall-title-order akmall-border ellipsis"
                        style={{ fontSize: '18px', display: 'flex' }}
                    >
                        {/* <i className="icon-cart"></i> */}
                        <div style={{ width: '100px', minWidth: '100px', height: '100px', marginRight: '8px' }}>
                            <Image
                                src={selectedPackage.imgUrl ? selectedPackage.imgUrl : commodity.mainImg}
                                style={{ width: '100px', height: '100px' }}
                            />
                        </div>
                        {commodity?.name}
                    </div>
                    <div className="akmall-form-content akmall-border">
                        <div>
                            <Packages
                                commodity={commodity}
                                region={region}
                                onPackageChange={this.props.onPackageChange}
                                onSpuAttrChange={this.props.onSpuAttrChange}
                            />
                            <ShipInfo
                                commodity={commodity}
                                config={config}
                                selectedPackage={selectedPackage}
                                onShipInfoChange={this.props.onShipInfoChange}
                                onSubmitOrder={this.props.onSubmitOrder}
                                onRemoteRegion={this.onRemoteRegionHandler}
                            />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}
