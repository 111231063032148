/* eslint-disable */
/* tslint:disable */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'src/decorator/inject-intl';
import Base from 'src/core/base';
import Toast from 'src/components/toast';
import { withRouter } from 'react-router-dom';

interface Prop {
    commodity: any;
}
interface State {
    queryKey: string | null;
}

@(withRouter as any)
@injectIntl()
export default class OrderQuery extends Base<Prop, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            queryKey: '',
        };
    }

    searchOrderHandler = async () => {
        let { queryKey } = this.state;
        const { commodity, intl } = this.props;
        if (queryKey) {
            queryKey = queryKey?.replace('#', '');
        }
        if (!queryKey?.trim()) {
            Toast.error(intl?.formatMessage({ id: 'input-phone-or-order-number' }) ?? 'Input phone or order number');
            return;
        }
        this.props.history?.push(`/cod/search-order/${commodity.id}/${queryKey}`);
    };

    render() {
        const { intl } = this.props;
        return (
            <div className="akmall-query clearfix">
                <div className="akmall-content">
                    <div className="akmall-rows clearfix rows-id-extends">
                        <label className="rows-head">
                            <FormattedMessage id="query-order" />
                        </label>
                        <div className="rows-params flex-b" style={{ width: 'unset' }}>
                            <input
                                type="tel"
                                name="kw"
                                placeholder={intl?.formatMessage({ id: 'input-phone-or-order-number' })}
                                className="akmall-input-text"
                                onChange={e => {
                                    this.setState({ queryKey: e.target.value?.trim() });
                                }}
                            />
                            <input
                                type="submit"
                                id="akmall-query-btn"
                                className="akmall-btn akmall-submit"
                                style={{
                                    width: '100px',
                                    marginLeft: '5px',
                                    padding: '0.5rem 0',
                                    backgroundColor: '#fbc39d',
                                    borderColor: '#fbc39d',
                                }}
                                value={intl?.formatMessage({ id: 'query.button' })}
                                onClick={this.searchOrderHandler}
                            />
                        </div>
                    </div>
                    {/* <div className="akmall-rows akmall-id-btn clearfix">
                        <input
                            type="submit"
                            id="akmall-query-btn"
                            className="akmall-btn akmall-submit"
                            value={intl?.formatMessage({ id: 'query.button' })}
                            onClick={this.searchOrderHandler}
                        />
                    </div> */}
                    {/* <div id="akmall-query-result" className="query_result"></div> */}
                </div>
            </div>
        );
    }
}
