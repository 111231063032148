/* eslint-disable */
/* tslint:disable */
import React, { Fragment, Component } from 'react';
import ReactDOM from 'react-dom';
import { checkSupportWebp } from '../../utils';

interface Prop {
    src: string;
    suffix?: string;
    alt?: string;
    style?: any;
    className?: string;
}

interface State {
    src: string;
}

export default class Image extends React.Component<Prop, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            src: '',
        };
    }
    static defaultProps = {};

    private setSrc(props: Prop) {
        const { src, suffix } = props;
        if (src) {
            if (suffix && checkSupportWebp()) {
                this.setState({ src: src + suffix });
            } else {
                this.setState({ src: src });
            }
        }
    }

    componentDidMount() {
        this.setSrc(this.props);
    }

    componentWillReceiveProps(nextProps: Prop) {
        if (this.props.src !== nextProps.src) {
            this.setSrc(nextProps);
        }
    }
    render() {
        const { alt = '', style = {}, className = '' } = this.props;
        return <img src={this.state.src} alt={alt} style={style} className={className} />;
    }
}
