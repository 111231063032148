import React from 'react';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'src/decorator/inject-intl';
import Base from 'src/core/base';
import BuyInfo from '../buy-info';

interface Prop {
    commodity: any;
    config: any;
    selectedPackage: any; //选中的套餐
    onPackageChange: (pkg: any) => void;
    onSpuAttrChange: (spuAttr: any) => void;
    onShipInfoChange: (spuAttr: any) => void;
    onSubmitOrder: (shipInfo: any) => void;
    onRemoteRegion: (region: any) => void;
}
interface State {}

@injectIntl()
export default class Description extends Base<Prop, State> {
    constructor(props: any) {
        super(props);
        this.state = {};
    }

    render() {
        const { commodity, config, selectedPackage } = this.props;
        return (
            <div className="box akmallbox-2">
                <h2 className="title">
                    <FormattedMessage id="commodity-description" />
                </h2>
                <div className="box-content akmall-detail-content">
                    <div
                        style={{ textAlign: 'center' }}
                        className="description-info "
                        dangerouslySetInnerHTML={{ __html: commodity?.description }}
                    ></div>
                    <BuyInfo
                        commodity={commodity}
                        config={config}
                        selectedPackage={selectedPackage}
                        onPackageChange={this.props.onPackageChange}
                        onSpuAttrChange={this.props.onSpuAttrChange}
                        onShipInfoChange={this.props.onShipInfoChange}
                        onSubmitOrder={this.props.onSubmitOrder}
                        onRemoteRegion={this.props.onRemoteRegion}
                    />
                </div>
                {/* <Favor promoteId={commodity.promoteId} /> */}
            </div>
        );
    }
}
