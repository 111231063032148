import request from 'src/service/http-service';

/**
 * 根据推广ID获取前端配置信息
 * @param id 推广ID
 */
export const getConfigInfo = (id: string) =>
    request({
        url: `/v1/config/info/${id}`,
        method: 'GET',
    });

/**
 * 创建订单
 * @param data 订单数据
 */
export const createOrder = (data: any) =>
    request({
        url: `/v1/order/create`,
        method: 'post',
        data,
    });

/**
 * 查询订单
 * @param orderId 订单ID
 */
export const getOrder = (orderId: string) =>
    request({
        url: `/v1/order/info/${orderId}`,
        method: 'get',
    });

/**
 * 用户自助查询订单，根据订单号或者手机号
 * @param data
 */
export const queryOrder = (data: any) =>
    request({
        url: `/v1/order/find`,
        method: 'post',
        data,
    });
/**
 * 查询订单
 * @param data 
    {
        "commodityId":"xxxx",
        "orderCodeOrPhone":"xxx"
    }
 */
export const searchOrder = (data: any) =>
    request({
        url: `/v1/order/find`,
        method: 'POST',
        data,
    });
/**
 * 获取商品信息
 * @param id 推广ID
 */
export const getCommodity = (promoteId: string) =>
    request({
        url: `/v1/commodity/info/${promoteId}`,
        method: 'get',
    });
/**
 * 获取用户须知
 * @param id 用户须知ID
 */
export const getUserInstruction = (id: string) =>
    request({
        url: `/v1/notice/info/${id}`,
        method: 'get',
    });

/**
 * 用户上传图片
 * @param id 用户须知ID
 */
export const uploadImage = (params: any, config: any) => request.post(`/v1/order/uploadImage`, params, config);

/**
 * 用户行为分析数据
 * @param data
 */
export const ub = (data?: any) =>
    request({
        url: `/v1/ub`,
        method: 'post',
        data,
    });
/**
 * 获取最新订单数据
 * @param data
 */
export const getLatestOrder = (promoteId: string, data: any) =>
    request({
        url: `v1/latestorder/${promoteId}/list`,
        method: 'post',
        data,
    });
/**
 * 获取推荐的商品数据
 * @param data
 */
export const getRecommendCommodities = (promoteId: string) =>
    request({
        url: `/v1/promote/${promoteId}/recommendcommoditys`,
        method: 'GET',
    });

/**
 * 上报日志
 * @param data 日志内容
 */
export const saveLog = (data: any) =>
    request({
        url: `/v1/ub/fb/log`,
        method: 'post',
        data,
    });

/**
 * 商品评论列表
 */
export const comments = (commodityId: string, data: any) =>
    request({
        url: `/v1/commodity/${commodityId}/comments`,
        method: 'post',
        data,
    });

/**
 * 根据收件地址查询偏远地区物流费
 * @param data
 */
export const remoteRegion = (data: any) =>
    request({
        url: `/v1/remoteRegion`,
        method: 'post',
        data,
    });
