/* eslint-disable */
/* tslint:disable */
import React from 'react';
import { FormattedMessage } from 'react-intl';
import { injectIntl } from 'src/decorator/inject-intl';
import Base from 'src/core/base';

import SpuAttr from '../spu-attr';
import { BusinessEvent } from 'src/common/constant';
import EventBusManager from 'src/core/event-bus-manager';

interface Prop {
    commodity: any;
    region: any;
    onPackageChange: (pkg: any) => void;
    onSpuAttrChange: (spuAttr: any) => void;
}
interface State {
    /** 选中的套餐 */
    selectedPackage: any;
}

@injectIntl()
export default class Packages extends Base<Prop, State> {
    constructor(props: any) {
        super(props);
        this.state = {
            selectedPackage: {},
        };
    }
    /** 切换套餐 */
    switchPackageHandler = (pkg: any) => {
        this.setState({ selectedPackage: pkg });
        this.props.onPackageChange(JSON.parse(JSON.stringify(pkg)));
    };

    spuAttrChangeHandler = (spuAttr: any) => {
        this.props.onSpuAttrChange(spuAttr);
    };

    componentDidMount() {
        const { commodity } = this.props;
        if (commodity?.comboArr?.length > 0) {
            this.setState({
                selectedPackage: commodity?.comboArr[0],
            });
            this.props.onPackageChange(JSON.parse(JSON.stringify(commodity?.comboArr[0])));
        }
    }

    componentWillReceiveProps(nextProps: Prop) {
        const { commodity: oldCommodity } = this.props;
        const { commodity: newCommodity } = nextProps;
        if (oldCommodity !== newCommodity && newCommodity?.comboArr?.length > 0) {
            this.setState({
                selectedPackage: newCommodity?.comboArr[0],
            });
            this.props.onPackageChange(JSON.parse(JSON.stringify(newCommodity?.comboArr[0])));
        }
    }

    render() {
        const { commodity, region } = this.props;
        const { selectedPackage } = this.state;
        return (
            <>
                <div className="akmall-box" id="akmall-box-product">
                    <div className="akmall-rows clearfix rows-id-params rows-id-params-radio akmall-radio">
                        <label className="rows-head">
                            <FormattedMessage id="ship-info.select-combo" />
                            <span className="akmall-request">*</span>
                        </label>
                        <div className="rows-params">
                            {commodity?.comboArr?.map((pkg: any) => {
                                return (
                                    <label
                                        key={pkg.id}
                                        className={`akmall-params-text akmall-params  akmall-radio ${
                                            pkg.id === selectedPackage.id ? 'active' : ''
                                        }`}
                                        title={pkg.name}
                                        onClick={this.switchPackageHandler.bind(this, pkg)}
                                    >
                                        <p className="item-desc">{pkg.name}</p>
                                    </label>
                                );
                            })}
                        </div>
                    </div>
                </div>
                <div className="akmall-box-params">
                    <div className="akmall-rows clearfix rows-id-price">
                        <label className="rows-head">
                            <FormattedMessage id="freight-charge" />
                        </label>
                        <div className="rows-params">
                            {commodity.currencyDisplayPosition === 'BEFORE_AMOUNT' && commodity.currencyDisplay}
                            <strong className="akmall-total-price">
                                {selectedPackage?.logisticsFee + region.remoteLogisticsFee}
                            </strong>
                            {commodity.currencyDisplayPosition === 'AFTER_AMOUNT' && commodity.currencyDisplay}
                        </div>
                    </div>
                    <div className="akmall-rows clearfix rows-id-price">
                        <label className="rows-head">
                            <FormattedMessage id="price" />
                        </label>
                        <div className="rows-params">
                            {commodity.currencyDisplayPosition === 'BEFORE_AMOUNT' && commodity.currencyDisplay}
                            <strong className="akmall-total-price">
                                {selectedPackage?.price + selectedPackage?.logisticsFee + region.remoteLogisticsFee}
                            </strong>
                            {commodity.currencyDisplayPosition === 'AFTER_AMOUNT' && commodity.currencyDisplay}
                        </div>
                    </div>
                </div>

                {<SpuAttr commodity={commodity} selectedPackage={selectedPackage} onSpuAttrChange={this.spuAttrChangeHandler} />}
            </>
        );
    }
}
